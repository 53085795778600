import { DialogController }   from 'aurelia-dialog';
import { bindable, inject }   from 'aurelia-framework';
import { BaseListViewModel }  from 'base-list-view-model';
import { CommentsRepository } from 'modules/core/custom-components/comments/services/repository';
import { AppContainer }       from 'resources/services/app-container';

@inject(AppContainer, CommentsRepository, DialogController)
export class CommentsListingModal extends BaseListViewModel {

    listingId = 'comments-modal-listing';

    @bindable headerTitle    = 'comments-modal-listings';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.comments',
        size:    'modal-lg',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.relatableId      = params.relatableId;
        this.forceFilterByLot = params.forceFilterByLot;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => {
                    return this.forceFilterByLot ? this.repository.search(this.relatableId, criteria) : this.repository.byTestHeader(this.relatableId, criteria);
                },
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'text',
                    name:  'comments.text',
                    title: 'form.field.comment',
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'entityTeam',
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                },
                {
                    data:   'created_by',
                    name:   'users.name',
                    title:  'form.field.created-by',
                    hidden: true,
                },
                {
                    data:            'created_at',
                    name:            'comments.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }
}
