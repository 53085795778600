import { bindable, containerless, inject } from 'aurelia-framework';
import { UsersRepository }                 from 'modules/administration/users/services/repository';
import { AlertMessagesRepository }         from 'modules/management/alert-messages/services/repository';
import { AppContainer }                    from 'resources/services/app-container';

@containerless()
@inject(AppContainer, AlertMessagesRepository, UsersRepository)
export class AlertMessagesCard {
    @bindable headerTitle = 'dashboard.title.alert-messages';

    criteria      = {};
    alertMessages = [];

    alertMessagesExpanded = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param usersRepository
     */
    constructor(appContainer, repository, usersRepository) {
        this.appContainer    = appContainer;
        this.authUser        = this.appContainer.authenticatedUser.user;
        this.repository      = repository;
        this.usersRepository = usersRepository;
    }

    /**
     * Handles activate event
     */
    async bind() {
        await this.fetchData();

        this.usersRepository.updateAlertMessages();
    }

    /**
     * Fetches data from server
     *
     * @returns {*}
     */
    async fetchData() {
        return Promise.all([
            this.repository.available(),
        ]).then(async ([alertMessages]) => {
            const fetchedAlertMessages = [...alertMessages];
            const readAlertMessages    = new Set(this.authUser.alert_messages.map(alertMessage => alertMessage.id));

            this.alertMessages = fetchedAlertMessages.map(alertMessage => ({
                ...alertMessage,
                viewed: readAlertMessages.has(alertMessage.id),
            })).sort((a, b) => a.viewed - b.viewed);
        });
    }

}
