import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class CommentsRepository extends BaseRepository {
    baseUrl = 'test-headers';

    /**
     * Performs a search given some criteria
     *
     * @param relatableId
     * @param criteria
     *
     * @returns {*}
     */
    search(relatableId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${relatableId}/comments/search`, criteria);
    }

    /**
     * Performs a search given some criteria
     *
     * @param relatableId
     * @param criteria
     *
     * @returns {*}
     */
    byTestHeader(relatableId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${relatableId}/comments/by-test-header`, criteria);
    }

    /**
     * Finds a record
     *
     * @param relatableId
     * @param id
     *
     * @returns {*}
     */
    find(relatableId, id) {
        return this.httpClient.get(`${this.baseUrl}/${relatableId}/comments/${id}`);
    }

    /**
     * Creates a record
     *
     * @param relatableId
     * @param data
     *
     * @returns {*}
     */
    create(relatableId, data) {
        return this.httpClient.post(`${this.baseUrl}/${relatableId}/comments`, { ...data, relatable_id: relatableId });
    }

    /**
     * Updates a record
     *
     * @param relatableId
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(relatableId, id, data) {
        return this.httpClient.post(`${this.baseUrl}/${relatableId}/comments/${id}`, { ...data, relatable_id: relatableId });
    }

    /**
     * Exports a search given some criteria
     *
     * @param relatableId
     * @param criteria
     *
     * @returns {*}
     */
    export(relatableId, criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/${relatableId}/comments/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Deletes a record
     *
     * @param relatableId
     * @param id
     *
     * @returns {*}
     */
    destroy(relatableId, id) {
        return this.httpClient.delete(`${this.baseUrl}/${relatableId}/comments/${id}`);
    }

    /**
     * Deletes selected records
     *
     * @param relatableId
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(relatableId, ids) {
        return this.httpClient.delete(`${this.baseUrl}/${relatableId}/comments/destroy-selected`, { ids: ids });
    }
}
